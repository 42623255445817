import { useProfileStore } from '~/stores/profileStore'

export const CIAM_LOGIN = 'ciamLogin'
export const CIAM_LOGIN_VERIFIED = 'ciamLogin-verified'
export interface WindowOptions {
  width: number
  height: number
  left?: number
  top?: number
  popup?: boolean
  noopener?: '_top' | '_self' | '_parent' | '_blank'
  noreferrer?: boolean
}

export interface PopupEvent {
  name: typeof CIAM_LOGIN
  loggedIn: boolean
  code: string
}
export interface WebsiteEvent {
  name: typeof CIAM_LOGIN_VERIFIED
  verified: boolean
}

const popupWindow = ref<WindowProxy | null>(null)

export const useCiamLogin = () => {
  const config = useRuntimeConfig()

  // CIAM login URL
  const loginUrl = computed<string>(() => {
    const { ciamClientId, ciamApiUrl, ciamCallbackUrl } = config.public
    return `${ciamApiUrl}/as/authorization.oauth2?response_type=code&client_id=${ciamClientId}&redirect_uri=${ciamCallbackUrl}&scope=openid%20profile%20email%20phone%20ciam-uid&state=af0ifjsldkj`
  })

  // CIAM logout URL
  const logoutUrl = `${config.public.ciamApiUrl}/idp/startSLO.ping?TargetResource=${config.public.baseUrl}/myaccount/logout`

  const onLogin = () => {
    openPopupWindow(loginUrl.value)
  }

  const getPopupOptions = (): string => {
    const options: WindowOptions = { width: 600, height: 400 }
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : 0
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : 0
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      window.screen.width ||
      300
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      window.screen.height ||
      300
    options.left = width / 2 - options.width / 2 + dualScreenLeft
    options.top = height / 2 - options.height / 2 + dualScreenTop

    return Object.keys(options)
      .reduce((prev: string[], key) => {
        prev.push(`${key}=${options[key as keyof WindowOptions]}`)

        return prev
      }, [])
      .join(',')
  }

  const openPopupWindow = (url: string) => {
    popupWindow.value = window.open(url, 'LOGIN', getPopupOptions())

    if (!popupWindow.value) {
      // eslint-disable-next-line no-console
      console.log(
        'Please unblock popups in your browser settings to login with CIAM'
      )
    } else {
      popupWindow.value.focus()
      popupWindow.value.location.href = url
    }
  }

  return { loginUrl, logoutUrl, onLogin }
}

export const useInitialiseCiamLogin = () => {
  const config = useRuntimeConfig()
  const profileStore = useProfileStore()

  const isTokenVerified = computed(() => profileStore.isTokenVerified)

  /**
   * Closes the popup window
   */
  const closeWindow = () => {
    popupWindow.value && popupWindow.value.close()
    popupWindow.value = null
  }

  const onMessage = async (e: MessageEvent<PopupEvent>) => {
    if (e.origin !== window.origin || e.data.name !== CIAM_LOGIN) return

    if (e.data.loggedIn && e.data.code) {
      await profileStore.loginProfile(e.data.code)
    } else {
      profileStore.logoutProfile()
      closeWindow()
    }
  }

  watch(
    isTokenVerified,
    (val) => {
      if (val) {
        const payload: WebsiteEvent = {
          name: CIAM_LOGIN_VERIFIED,
          verified: true
        }

        popupWindow.value?.postMessage(payload, config.public.ciamCallbackUrl)
        setTimeout(closeWindow, 3000)
      }
    },
    { deep: true }
  )

  onMounted(() => {
    window.addEventListener('message', onMessage, false)
  })

  onUnmounted(() => {
    window.removeEventListener('message', onMessage, false)
  })
}
